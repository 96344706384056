import {
  AppShell,
  rem,
  Flex,
  Button,
  Burger,
  Box,
  Group,
  Text,
  Stack,
  Menu,
  NavLink,
} from "@mantine/core";
import { useTranslation } from "next-i18next";
import {
  IconChevronDown,
  IconPhone,
  IconTicket,
  IconWorld,
} from "@tabler/icons-react";
import Link from "next/link";
import Image from "next/image";
import { getAssetUrl, useDeviceQuery } from "@/utils";
import GlobalNavi from "@/components/GlobalNavi";

export function Header({
  openLanguageModal,
  openCurrencyModal,
  selectedCurrency,
  opened,
  toggle,
  hasGlobalNavi,
}: {
  openLanguageModal: () => void;
  openCurrencyModal: () => void;
  toggle: () => void;
  opened: boolean;
  selectedCurrency?: string;
  hasGlobalNavi?: boolean;
}): JSX.Element {
  const [isMobile] = useDeviceQuery();
  const { t, i18n, ready } = useTranslation();
  const guideLinks = t("common:guide", { returnObjects: true }) as any;
  const lang =
    i18n.language.length === 2 ? i18n.language : i18n.language.split("-")[1];

  return (
    <AppShell.Header
      className={`lg:px-0 bg-blue-9 ${hasGlobalNavi ? "h-[140px]" : "h-17"}`}
    >
      <Group className="mx-auto my-0 max-w-[1350px] justify-between md:px-4 h-17 gap-0 px-2.5">
        <Flex className="h-full items-center justify-start">
          <Box
            className="h-full mr-2 block lg:hidden"
            component={Link}
            href="/"
            locale={i18n.language}
            shallow={true}
          >
            <Image
              src={getAssetUrl(`/logo/${i18n.language}.svg`)}
              alt={t("meta:home.title")}
              priority={isMobile}
              width={187}
              height={70}
            />
          </Box>
          <Box
            className="h-full hidden lg:block"
            component={Link}
            href="/"
            locale={i18n.language}
            shallow={true}
          >
            <Image
              src={getAssetUrl(`/logo/tse-${i18n.language}.svg`)}
              alt={t("meta:home.title")}
              width={371}
              height={70}
            />
          </Box>
        </Flex>
        <Button
          variant="filled"
          color="white"
          className="text-blue-9 uppercase block sm:hidden"
          fw={400}
          h={rem(46)}
          w={rem(70)}
          px={0}
          component={Link}
          href={`${process.env.NEXT_PUBLIC_NAVITIME_SUBMIT_URL}/${i18n.language}/booking/jr/mypage/login`}
        >
          <Stack gap={0} align="center">
            <IconTicket height={20} width={20} />
            <Text size={rem(10)} className="whitespace-break-spaces">
              {t("common:links.my_orders")}
            </Text>
          </Stack>
        </Button>
        <Button
          variant="subtle"
          classNames={{
            root: "text-white uppercase px-1 rounded-full hover:bg-blue-7 !h-12 block sm:hidden",
            label: "flex flex-col justify-center items-center !font-normal",
            section: "ml-0.5",
          }}
          onClick={openLanguageModal}
        >
          <IconWorld size={26} className="stroke-1" />
          <Text className="text-xxs !text-white">{lang}</Text>
        </Button>
        <Flex gap="xs" visibleFrom="md" align="center" ml={0}>
          <Flex>
            <Link href="/" locale={i18n.language} shallow={true}>
              <Button
                variant="subtle"
                className="text-white rounded-full hover:bg-blue-7 px-1.5 font-normal"
              >
                {t("common:links.home")}
              </Button>
            </Link>
            <Menu shadow="md" width={rem(250)}>
              <Menu.Target>
                <Button
                  variant="subtle"
                  classNames={{
                    root: "text-white rounded-full hover:bg-blue-7 px-1.5 font-normal",
                    section: "ml-1",
                  }}
                  rightSection={
                    <IconChevronDown height={16} width={16} strokeWidth={1.5} />
                  }
                >
                  {t("common:links.boarding_guide")}
                </Button>
              </Menu.Target>

              {ready && (
                <Menu.Dropdown>
                  {Array.isArray(guideLinks) &&
                    guideLinks?.map((link: any, index: number) => (
                      <Box key={index} px="xs">
                        {link.subcontent ? (
                          <NavLink component="button" label={t(link.title)}>
                            {link?.subcontent?.map(
                              (content: any, index: number) => (
                                <Link
                                  key={index}
                                  href={`/guide/${link.url}/${content.url}`}
                                  locale={i18n.language}
                                  shallow={true}
                                >
                                  <NavLink
                                    component="button"
                                    label={content.title}
                                  />
                                </Link>
                              ),
                            )}
                          </NavLink>
                        ) : (
                          <Link
                            href={`/guide/${link.url}`}
                            locale={i18n.language}
                            shallow={true}
                          >
                            <NavLink component="button" label={t(link.title)} />
                          </Link>
                        )}
                      </Box>
                    ))}
                </Menu.Dropdown>
              )}
            </Menu>

            <Link href="/faq" locale={i18n.language} shallow={true}>
              <Button
                variant="subtle"
                className="text-white rounded-full hover:bg-blue-7 px-1.5 font-normal"
              >
                {t("common:links.faq")}
              </Button>
            </Link>
            <Link href="/contact" locale={i18n.language} shallow={true}>
              <Button
                variant="subtle"
                className="text-white rounded-full hover:bg-blue-7 px-1.5 font-normal"
              >
                {t("common:links.contact")}
              </Button>
            </Link>
          </Flex>
          <Button
            variant="filled"
            color="white"
            className="!text-blue-9 uppercase px-2"
            fw={400}
            h={rem(46)}
            leftSection={<IconTicket height={16} width={16} />}
            component={Link}
            href={`${process.env.NEXT_PUBLIC_NAVITIME_SUBMIT_URL}/${i18n.language}/booking/jr/mypage/login`}
            rel="noopener"
          >
            {t("common:links.my_orders")}
          </Button>
          <Link href={`tel:${t("common:service.contact")}`} shallow={true}>
            <Stack
              gap={0}
              px={rem(15)}
              py={rem(5)}
              align="center"
              className="border border-solid border-white rounded-md"
              visibleFrom="lg"
            >
              <Group gap={rem(5)}>
                <IconPhone className="text-white" height={16} width={16} />
                <Text c="white" size="sm">
                  {t("common:service.contact")}
                </Text>
              </Group>
              <Text c="white" size="xs">
                {t("common:service.business_hours")}
              </Text>
            </Stack>
          </Link>
          <Button
            variant="subtle"
            color="dark.0"
            fw={400}
            h={rem(30)}
            rightSection={<IconChevronDown height={16} width={16} />}
            classNames={{
              root: "text-white uppercase px-1 rounded-full hover:bg-blue-7 !h-12",
              label: "flex flex-col justify-center items-center",
              section: "ml-0.5",
            }}
            onClick={openLanguageModal}
          >
            <IconWorld size={20} className="stroke-1" />
            <Text className="text-xxs !text-white">{lang}</Text>
          </Button>
          <Button
            variant="subtle"
            color="dark.0"
            fw={400}
            w={"5rem"}
            h={rem(30)}
            rightSection={<IconChevronDown height={16} width={16} />}
            classNames={{
              root: "text-white uppercase px-1 rounded-full hover:bg-blue-7",
              section: "ml-0.5",
            }}
            onClick={openCurrencyModal}
          >
            {selectedCurrency}
          </Button>
        </Flex>
        <Burger
          opened={opened}
          onClick={toggle}
          hiddenFrom="md"
          size="md"
          color="white"
        />
      </Group>
      {hasGlobalNavi && <GlobalNavi />}
    </AppShell.Header>
  );
}
