import { AppShell, RemoveScroll } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { CURRENCIES, LANG, TYPE } from "@/constants/locale";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { FooterBreadcrumbs } from "./FooterBreadcrumbs";
import { IBreadcrumbs } from "@/types";
import {
  IconHelpCircle,
  IconMail,
  IconInfoCircle,
  IconNotes,
  IconTicket,
  IconClockHour3,
  IconTrain,
  IconBookmark,
} from "@tabler/icons-react";
import { useRouter } from "next/router";
import { WITH_GLOBALNAVI } from "@/constants";
import { useTranslation } from "next-i18next";

const Aside = dynamic(() => import("./Aside"));
const SelectModal = dynamic(() => import("@/components/SelectModal"));

interface Props {
  children: React.ReactNode;
  hasNavbar?: boolean;
  footer?: {
    breadcrumbs?: IBreadcrumbs[];
  };
}

export function Layout({ hasNavbar, children, footer }: Props): JSX.Element {
  const { i18n } = useTranslation();
  const [opened, { toggle }] = useDisclosure();
  const [
    currencyModalOpened,
    { open: openCurrencyModal, close: closeCurrencyModal },
  ] = useDisclosure(false);
  const [
    languageModalOpened,
    { open: openLanguageModal, close: closeLanguageModal },
  ] = useDisclosure(false);
  const [selectedCurrency, setSelectedCurrency] = useState<
    string | undefined
  >();
  const currency = getCookie("currency") || "JPY";
  const router = useRouter();
  const { query } = router;
  const [hasGlobalNavi, setHasGlobalNavi] = useState<boolean>(false);

  useEffect(() => {
    if (query.cid) {
      setHasGlobalNavi(
        Object.values(WITH_GLOBALNAVI).some((serviceName) =>
          String(query.cid).includes(serviceName),
        ) && i18n.language === "ko",
      );
    }
  }, [query.cid]);

  const navIcons: {
    [key: string]: React.ReactNode;
  } = {
    faq: <IconHelpCircle strokeWidth={1.5} />,
    contact: <IconMail strokeWidth={1.5} />,
    shinkansen: <IconInfoCircle strokeWidth={1.5} />,
    booking: <IconNotes strokeWidth={1.5} />,
    ticketing: <IconTicket strokeWidth={1.5} />,
    boarding: <IconClockHour3 strokeWidth={1.5} />,
    inside: <IconTrain strokeWidth={1.5} />,
    update: <IconBookmark strokeWidth={1.5} />,
  };

  useEffect(() => {
    const currencyData = CURRENCIES.find((item) => item.key === currency);
    setSelectedCurrency(currencyData?.symbol + " " + currencyData?.key);
  }, [currency]);

  return (
    <>
      <AppShell
        layout="default"
        withBorder={false}
        top={0}
        aside={{
          width: 300,
          breakpoint: "md",
          collapsed: { desktop: true, mobile: !opened },
        }}
        padding="md"
      >
        <Header
          opened={opened}
          toggle={toggle}
          openLanguageModal={openLanguageModal}
          openCurrencyModal={openCurrencyModal}
          selectedCurrency={selectedCurrency}
          hasGlobalNavi={hasGlobalNavi}
        />
        <AppShell.Main
          className={`px-0 pb-0 ${
            hasGlobalNavi ? "pt-[140px]" : "pt-[70px]"
          } min-h-[75dvh] relative`}
        >
          {children}
        </AppShell.Main>
        <RemoveScroll enabled={opened}>
          <Aside
            opened={opened}
            toggle={toggle}
            openLanguageModal={openLanguageModal}
            openCurrencyModal={openCurrencyModal}
            selectedCurrency={selectedCurrency}
            navIcons={navIcons}
          />
        </RemoveScroll>
        {footer?.breadcrumbs && (
          <FooterBreadcrumbs
            items={footer?.breadcrumbs}
            hasNavbar={hasNavbar}
            className={`border border-solid border-l-0 border-r-0 border-b-0 
                        border-dark-5 ${
                          hasNavbar && "lg:ml-80 lg:w-[calc(100%-320px)]"
                        }`}
          />
        )}
        <Footer />
      </AppShell>

      <SelectModal
        opened={languageModalOpened}
        close={closeLanguageModal}
        data={LANG}
        dataType={TYPE.LANG}
      />
      <SelectModal
        opened={currencyModalOpened}
        close={closeCurrencyModal}
        data={CURRENCIES}
        dataType={TYPE.CURRENCY}
      />
    </>
  );
}
